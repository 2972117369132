/**
 * 登录页
 * luxinwen
 * 2020-02
 */
<style scoped lang="less">
  @import "./login.less";
</style>

<template>
  <div class="login">
    <div class="login-main">
      <div class="login-main-icon"></div>
      <div class="login-main-content">
        <div class="title">欢迎登录{{ sysName }}</div>
        <Form class="login-form" ref="loginForm" :model="formData" :rules="formRule">
          <FormItem prop="loginUserName">
            <Input v-model="formData.loginUserName" size="large" prefix="ios-person-outline" placeholder="请输入用户名" @on-enter="login" />
          </FormItem>
          <FormItem prop="password">
            <Input v-model="formData.password" size="large" prefix="ios-lock-outline" placeholder="请输入密码" type="password" password @on-enter="login" />
          </FormItem>
          <FormItem prop="captcha">
            <Input v-model="formData.captcha" size="large" maxlength="4" class="width-xx" placeholder="请输入验证码" @on-enter="login" />
            <img src="" alt="" class="caimg" id="base64ImageCode" @click="changeBase64Image" />
          </FormItem>
          <FormItem>
            <Button class="btn-submit" type="primary" long @click="login" :loading="btnLoading">登 录</Button>
          </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
  import md5 from 'md5';

  export default {
    data() {
      return {
        sysName: process.env.VUE_APP_NAME,
        btnLoading: false,
        formData: {
          loginUserName: '',
          password: '',
          captcha: '',
          captchaUuid: ''
        },
        formRule: {
          loginUserName: [
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur'
            }
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              type: 'string',
              min: 6,
              message: '密码不少于6个字符',
              trigger: 'blur'
            }
          ],
          captcha: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            }
          ]
        }
      };
    },
    created() {
      this.changeBase64Image();
    },
    methods: {
      /**
       * 获取验证码
       */
      changeBase64Image() {
        this.$axios({
          url: this.$api.system.loginCaptcha,
          data: {}
        }).then(data => {
          document.getElementById('base64ImageCode').src = data.img;
          this.formData.captchaUuid = data.captchaUuid;
        });
      },
      /**
       * 登录
       */
      login() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            let params = Object.assign({}, this.formData);
            params.password = md5(params.password);
            this.$axios({
              url: this.$api.system.login,
              data: params,
              beforeSend: () => {
                this.clearUserInfo();
                this.btnLoading = true;
              },
              complete: () => {
                this.btnLoading = false;
              }
            }).then(data => {
              console.log('login', data);
              let uInfo = {
                username: data.userName,
                name: data.loginUserName,
                token: data.token,
                menus: data.menus,
                userMobile: data.userMobile
              };
              this.setUserInfo(uInfo);
              if (window.sessionStorage) {
                sessionStorage.setItem(`${process.env.VUE_APP_CODE}_${uInfo.name}`, '');
              }
              this.$router.push({
                name: 'index'
              });
            }).catch(error => {
              this.changeBase64Image();
            });
          }
        });
      }
    }
  };
</script>